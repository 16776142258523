<template>
  <div class="filters">
    <v-row>
      <v-col>Filter:</v-col>
    </v-row>
    <v-row class="align-end">
      <v-col lg="3" v-if="kaufpreis">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Kaufpreis"
          single-line
          hide-details
          @input="filter($event, 'kaufpreis')"
          @click:clear="clear('kaufpreis')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="darlehen">
        <v-select
          :color="color"
          :attach="true"
          label="Darlehen"
          item-color="selected"
          hide-details
          item-text="text"
          item-value="value"
          @input="filter($event, 'darlehen')"
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          :items="values"
        >
          <template #selection="{ item }">
            <span>{{ item.text }}</span>
          </template>
          <template v-slot:item="{ item }"
            ><span>{{ item.text }}</span></template
          >
        </v-select>
      </v-col>
      <v-col lg="3" v-if="sollzins">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Sollzins"
          single-line
          hide-details
          @input="filter($event, 'sollzins')"
          @click:clear="clear('sollzins')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="anbieter">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Anbieter"
          single-line
          hide-details
          @input="filterAnbieter"
          @click:clear="clear('anbieter')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="rendite">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Rendite"
          single-line
          hide-details
          @input="filter($event, 'rendite')"
          @click:clear="clear('rendite')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="zinssatz">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Zinssatz"
          single-line
          hide-details
          @input="filter($event, 'zinssatz')"
          @click:clear="clear('zinssatz')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="effectivzins">
        <v-text-field
          :color="color"
          clear-icon="mdi-close-circle"
          :append-icon="'mdi-table-search'"
          clearable
          label="Effektivzins"
          single-line
          hide-details
          @input="filter($event, 'effektivzins')"
          @click:clear="clear('effektivzins')"
        >
        </v-text-field>
      </v-col>
      <v-col lg="3" v-if="datum" class="picker-container">
        <div class="data-picker">
          <v-menu
            :color="color"
            ref="menu"
            :close-on-content-click="false"
            v-model="menu"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex">
                <v-icon
                  v-on="on"
                  class="mdiCalendar"
                  :class="{
                    'active-calendar': attrs['aria-expanded'] === 'true',
                  }"
                  >{{ 'mdi-calendar' }}
                </v-icon>
              </div>
            </template>
            <v-date-picker
              locale="de-DE"
              :show-current="max"
              :allowed-dates="allowedDates"
              :color="color"
              v-model="date"
              @input="filterDate"
              :min="min"
              :max="max"
            ></v-date-picker>
          </v-menu>
          <p v-if="date" class="date">{{ $date(date).format('DD.MM.YYYY') }}</p>
          <p v-else class="date">Datum</p>
          <v-icon small v-if="date" class="close-circle" @click="clearDate">
            {{ 'mdi-close-circle' }}
          </v-icon>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'Filters',
  props: {
    headers: Array,
    copy: Array,
    rows: Array,
    sollzins: {
      type: Boolean,
      default: false,
    },
    anbieter: {
      type: Boolean,
      default: false,
    },
    effectivzins: {
      type: Boolean,
      default: false,
    },
    datum: {
      type: Boolean,
      default: false,
    },
    zinssatz: {
      type: Boolean,
      default: false,
    },
    rendite: {
      type: Boolean,
      default: false,
    },
    kaufpreis: {
      type: Boolean,
      default: false,
    },
    darlehen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: [],
      menu: false,
      date: null,
      min: '',
      max: '',
      color: BASE_BLACK_COLOR,
      filters: {
        darlehen: '',
        sollzins: '',
        rendite: '',
        zinssatz: '',
        anbieter: '',
        effektivzins: '',
        kaufpreis: '',
        date: null,
      },
    };
  },
  watch: {
    copy(val) {
      const result = val.map((item) => {
        return new Date(item.datum).getTime();
      });
      this.min = this.$date(new Date(Math.min(...result))).format('YYYY-MM-DD');
      this.max = this.$date(new Date(Math.max(...result))).format('YYYY-MM-DD');
    },
    rows: {
      immediate: true,
      handler(val) {
        if (val.some((item) => item.darlehen)) {
          const arr = [];
          val.filter(function (item) {
            const i = arr.findIndex((x) => x.darlehen === item.darlehen);
            if (i <= -1) {
              arr.push(item);
            }
            return null;
          });
          arr.map((item) => {
            if (item.darlehen) {
              this.values.push({
                text: item.darlehen.toLocaleString('de-DE'),
                value: item.darlehen,
              });
            }
          });
        }
      },
    },
    filters() {
      this.customFilter();
    },
  },
  methods: {
    allowedDates(val) {
      return this.copy.find((item) => {
        return this.$date(item.datum).format('YYYY-MM-DD') === val;
      });
    },
    clear(key) {
      this.filters = { ...this.filters, [key]: '' };
    },
    clearDate() {
      this.date = null;
      this.filters = { ...this.filters, date: null };
    },
    customFilter() {
      const items = this.rows;
      const filters = this.filters;
      const multiFilters = new this.$MultiFilters(items, filters);
      ['kaufpreis', 'rendite', 'zinssatz', 'effektivzins', 'sollzins'].forEach(
        (filterName) => {
          return multiFilters.registerFilter(
            filterName,
            function (searchWord, items) {
              if (searchWord === '' || searchWord === null) return items;
              return items.filter((item) => {
                return String(item[filterName]).indexOf(searchWord) === 0;
              });
            }
          );
        }
      );
      multiFilters.registerFilter('darlehen', function (searchWord, items) {
        if (searchWord === '' || searchWord === null) return items;
        return items.filter((item) => {
          return item.darlehen === searchWord;
        });
      });
      multiFilters.registerFilter('anbieter', function (searchWord, items) {
        if (searchWord === '' || searchWord === null) return items;
        return items.filter((item) => {
          return item.anbietername
            .toLowerCase()
            .includes(searchWord.toLowerCase());
        });
      });
      multiFilters.registerFilter('date', (date, items) => {
        if (date === null) return items;
        return items.filter((item) => {
          return new Date(item.datum).getTime() === date;
        });
      });
      return this.$emit('changed', multiFilters.runFilters());
    },
    filter(e, key) {
      if (key === 'darlehen') {
        this.filters = this.$MultiFilters.updateFilters(this.filters, {
          [key]: e,
        });
      } else {
        const value = e?.includes(',') ? e.replace(/,/g, '.') : e;
        this.filters = this.$MultiFilters.updateFilters(this.filters, {
          [key]: value,
        });
      }
    },
    filterAnbieter(e) {
      this.filters = this.$MultiFilters.updateFilters(this.filters, {
        anbieter: e,
      });
    },
    filterDate(e) {
      this.$refs.menu.save(e);
      const timestamp = new Date(e + 'T00:00:00').getTime();
      this.filters = this.$MultiFilters.updateFilters(this.filters, {
        date: timestamp,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.active-calendar {
  color: $text;
}
.filters > div:nth-of-type(2) {
  margin-bottom: 10px;
  & > div {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
  }
  & > .picker-container {
    padding-top: 10px;
  }
  & > div:last-child {
    padding-bottom: 0;
    margin-top: 5px;
  }
}
.data-picker {
  display: flex;
}
.close-circle {
  margin-left: 5px;
  height: 15px;
  &:hover {
    cursor: pointer;
  }
}
.mdi-close-circle {
  font-size: 15px !important;
}
.date {
  margin-left: 10px;
  margin-bottom: 0 !important;
  color: rgba(0, 0, 0, 0.6);
}
@media screen and(max-width: 500px) {
  .filters > div:last-child {
    flex-direction: column;
  }
}
</style>
